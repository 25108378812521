<template>
  <CustomBottomSheet
    :refName="'filterSheet'"
    size="lg"
    :headerText="$t('filter')"
    :headerIcon="icon"
  >
    <PreLoader v-if="isLoading" />
    <div class="row">
      <CustomSelectBox
        :className="'col-md-6'"
        :id="`userToken`"
        :value="filterData.userToken"
        :options="userTokenOptions"
        v-on:changeValue="filterData.userToken = $event"
        :title="$t('students.name')"
        :imgName="'user.svg'"
      />

      <CustomSelectBox
        :className="'col-md-6'"
        :id="`debtTypeToken`"
        :value="filterData.debtTypeToken"
        :options="debtTypeTokenOptions"
        v-on:changeValue="filterData.debtTypeToken = $event"
        :title="$t('general.type')"
        :imgName="'type.svg'"
      />
      <BaseTree
        :hierarchySystemTypeFilterToken="hierarchySystemTypeFilterToken"
        :treeModel="getTreeModel"
        v-on:changeValue="
          baseTreeChanged($event);
          getEducationalGroupsDialog();
        "
        :showParent="true"
        :filterParentDirect="true"
      />
      <CustomSelectBox
        :className="'col-md-12'"
        :id="`educationalGroupToken`"
        :value="filterData.educationalGroupToken"
        :options="educationalGroupTokenOptions"
        v-on:changeValue="filterData.educationalGroupToken = $event"
        :title="$t('EducationalGroups.select')"
        :imgName="'EducationalGroups.svg'"
      />
      <CustomInputFloat
        :className="'col-md-6'"
        :id="`debtMoneyFrom`"
        :value="filterData.debtMoneyFrom"
        v-on:changeValue="filterData.debtMoneyFrom = $event"
        :title="$t('general.valueFrom')"
        :imgName="'money.svg'"
      />
      <CustomInputFloat
        :className="'col-md-6'"
        :id="`debtMoneyTo`"
        :value="filterData.debtMoneyTo"
        v-on:changeValue="filterData.debtMoneyTo = $event"
        :title="$t('general.valueTo')"
        :imgName="'money.svg'"
      />
      <CustomInputFloat
        :className="'col-md-6'"
        :id="`paidMoneyFrom`"
        :value="filterData.paidMoneyFrom"
        v-on:changeValue="filterData.paidMoneyFrom = $event"
        :title="$t('general.paidFrom')"
        :imgName="'money.svg'"
      />
      <CustomInputFloat
        :className="'col-md-6'"
        :id="`paidMoneyTo`"
        :value="filterData.paidMoneyTo"
        v-on:changeValue="filterData.paidMoneyTo = $event"
        :title="$t('general.paidTo')"
        :imgName="'money.svg'"
      />
      <CustomInputFloat
        :className="'col-md-6'"
        :id="`remainderMoneyFrom`"
        :value="filterData.remainderMoneyFrom"
        v-on:changeValue="filterData.remainderMoneyFrom = $event"
        :title="$t('general.remaindingFrom')"
        :imgName="'money.svg'"
      />
      <CustomInputFloat
        :className="'col-md-6'"
        :id="`remainderMoneyTo`"
        :value="filterData.remainderMoneyTo"
        v-on:changeValue="filterData.remainderMoneyTo = $event"
        :title="$t('general.remaindingTo')"
        :imgName="'money.svg'"
      />
    </div>
    <CustomBaseEntityFilter
      :className="'row'"
      :value="filterData"
      v-on:changeValue="filterData.fillData($event)"
    />

    <div class="form-actions">
      <div class="icon-submit" @click.prevent="search">
        <img src="@/assets/images/search-icon.svg" :title="$t('search')" />
      </div>
      <div @click.prevent="closeBottomSheet('filterSheet')" class="icon-cancel">
        <img src="@/assets/images/cancel-icon.svg" :title="$t('cancel')" />
      </div>
    </div>
  </CustomBottomSheet>
</template>

<script>
import CustomBottomSheet from "./../../../../components/general/CustomBottomSheet.vue";
import PreLoader from "./../../../../components/general/PreLoader.vue";
import CustomSelectBox from "./../../../../components/general/CustomSelectBox.vue";
import BaseTree from "./../../../../components/general/BaseTree.vue";
import CustomInputFloat from "./../../../../components/general/CustomInputFloat.vue";
import CustomBaseEntityFilter from "./../../../../components/general/CustomBaseEntityFilter.vue";
import generalMixin from "./../../../../utils/generalMixin";
import icon from "@/assets/images/filter.svg";
import { USER_TYPE, SYSTEM_TYPE } from "./../../../../utils/constantLists";
import {
  getUsersDialog,
  getEducationalGroupsDialog,
} from "./../../../../utils/dialogsOfApi";
import { getDialogOfDebtType } from "./../../../../utils/dialogsOfConstantsLists";

export default {
  mixins: [generalMixin],
  props: {
    theFilterData: {
      type: Object,
    },
  },
  data() {
    return {
      filterData: this.theFilterData,
      icon,
      userTokenOptions: [],
      debtTypeTokenOptions: getDialogOfDebtType(),
      educationalGroupTokenOptions: [],
    };
  },
  components: {
    CustomBottomSheet,
    PreLoader,
    CustomSelectBox,
    BaseTree,
    CustomInputFloat,
    CustomBaseEntityFilter,
  },
  computed: {
    hierarchySystemTypeFilterToken() {
      return SYSTEM_TYPE.EducationalActivity;
    },
    getTreeModel() {
      let treeModelObject = {
        systemComponentHierarchyToken:
          this.filterData.systemComponentHierarchyToken,
        hierarchyTitle: this.$t(
          "SystemComponentsHierarchies.selectEducationalActivity"
        ),
        systemComponentToken: this.filterData.systemComponentToken,
        componentTitle: this.$t("SystemComponents.selectEducationalActivity"),
        parentToken: this.filterData.educationalCategoryToken,
        parentTitle: this.$t("EducationalCategories.select"),
        parentImgName: "EducationalCategories.svg",
      };
      return treeModelObject;
    },
  },

  methods: {
    async getStudentsDialog() {
      this.isLoading = true;
      let params = {
        modelName: "students",
        userTypeToken: USER_TYPE.Student,
      };
      this.userTokenOptions = await getUsersDialog(params);
      this.isLoading = false;
    },
    baseTreeChanged(data) {
      this.filterData.systemComponentHierarchyToken = data.hierarchyToken;
      this.filterData.systemComponentToken = data.componentToken;
      this.filterData.educationalCategoryToken = data.parentToken;
      this.filterData.educationalCategoryTokens = [data.parentToken];
    },
    async getEducationalGroupsDialog() {
      this.isLoading = true;
      this.educationalGroupTokenOptions = [];
      let params = {
        educationalCategoryToken: this.filterData.educationalCategoryToken,
      };
      this.educationalGroupTokenOptions = await getEducationalGroupsDialog(
        params
      );
      this.isLoading = false;
    },
    search() {
      this.closeBottomSheet("filterSheet");
      this.$emit("search", this.filterData);
    },
  },
  created() {
    this.getStudentsDialog();
    this.getEducationalGroupsDialog();
  },
};
</script>
