<template>
  <CustomBottomSheet
    :refName="'DebtInfo'"
    size="xl"
    :headerText="$t('Debts.data')"
    :headerIcon="debt.icon"
  >
    <!-- students.data -->
    <div class="my-card">
      <span class="my-card-title">{{ $t("students.data") }}</span>
      <span class="my-card-title end">
        <router-link
          :to="{
            name: 'Students',
            params: {
              userToken: debt.userInfoData.userToken,
            },
          }"
          target="_blank"
          :title="$t('students.data')"
        >
          <img src="@/assets/images/info.svg" />
        </router-link>
      </span>

      <div class="row">
        <DataLabelGroup
          :className="'col-md-6'"
          :value="debt.userInfoData.fullCode"
          :title="$t('code')"
          :imgName="'code.svg'"
        />
        <DataLabelGroup
          :className="'col-md-6'"
          :value="debt.userInfoData.userNameCurrent"
          :title="$t('Users.name')"
          :imgName="'students.svg'"
        />
        <DataLabelGroup
          :className="'col-md-6 phone-number'"
          :value="debt.userInfoData.userPhoneWithCC"
          :title="$t('Users.phone')"
          :imgName="'phone.svg'"
        />
        <DataLabelGroup
          :className="'col-md-6'"
          :value="debt.userInfoData.userEmail"
          :title="$t('Users.email')"
          :imgName="'email.svg'"
        />
      </div>
    </div>

    <!-- Debts.data -->
    <div class="my-card">
      <span class="my-card-title">{{ $t("Debts.data") }}</span>
      <span class="my-card-title end">
        <router-link :to="routeTo" :title="$t('Debts.onInfo')" target="_blank">
          <img class="icon-lg" src="@/assets/images/info.svg" />
        </router-link>
      </span>
      <div class="row">
        <DataLabelGroup
          :className="'col-md-4'"
          :value="debt.fullCode"
          :title="$t('code')"
          :imgName="'code.svg'"
        />
        <DataLabelGroup
          :className="'col-md-4'"
          :value="debt.debtTypeNameCurrent"
          :title="$t('general.type')"
          :imgName="'type.svg'"
        />
        <DataLabelGroup
          :className="'col-md-4'"
          :value="debt.schedulingData.schedulingStatusTypeNameCurrent"
          :title="$t('Debts.schedulingStatusType')"
          :imgName="'type.svg'"
        />
        <DataLabelGroup
          :className="'col-md-4'"
          :value="debt.debtMoneyStatistics.debtMoneyWithCurrency"
          :title="$t('general.value')"
          :imgName="'money.svg'"
        />
        <DataLabelGroup
          :className="'col-md-4'"
          :value="debt.debtMoneyStatistics.paidMoneyWithCurrency"
          :title="$t('general.paid')"
          :imgName="'money.svg'"
        />
        <DataLabelGroup
          :className="'col-md-4'"
          :value="debt.debtMoneyStatistics.remainderMoneyWithCurrency"
          :title="$t('general.remainding')"
          :imgName="'money.svg'"
        />
      </div>
    </div>

    <div
      class="my-card"
      v-if="
        debt.schedulingData.schedulingStatusTypeToken ==
          SCHEDULING_STATUS_TOKENS.IsHaveSchedule &&
        checkPrivilege(hasInstallmentScheduleTime())
      "
    >
      <span class="my-card-title">{{
        $t("Installments.dataScheduleTime")
      }}</span>
      <span class="my-card-title end">
        <router-link
          :to="{
            name: 'InstallmentScheduleTimes',
            params: {
              userToken: ' ',
              debtToken: debt.debtToken,
            },
          }"
          :title="$t('Installments.dataScheduleTime')"
          target="_blank"
        >
          <img class="icon-lg" src="@/assets/images/info.svg" />
        </router-link>
      </span>
      <div class="row">
        <DataLabelGroup
          :className="'col-md-6'"
          :value="debt.schedulingData.schedulingStatusTypeNameCurrent"
          :title="$t('Debts.schedulingStatusType')"
          :imgName="'type.svg'"
        />

        <DataLabelGroup
          :className="'col-md-6'"
          :value="
            formateDateTimeLang(
              debt.schedulingData.schedulingDate,
              debt.schedulingData.schedulingTime
            )
          "
          :title="$t('dateTime')"
          :imgName="'dateAndTime.svg'"
        />
        <DataLabelGroup
          :className="'col-md-4'"
          :value="debt.debtMoneyStatistics.debtMoneyWithCurrency"
          :title="$t('general.value')"
          :imgName="'money.svg'"
        />
        <DataLabelGroup
          :className="'col-md-4'"
          :value="debt.debtMoneyStatistics.paidMoneyWithCurrency"
          :title="$t('general.paid')"
          :imgName="'money.svg'"
        />
        <DataLabelGroup
          :className="'col-md-4'"
          :value="debt.debtMoneyStatistics.remainderMoneyWithCurrency"
          :title="$t('general.remainding')"
          :imgName="'money.svg'"
        />
      </div>
    </div>
  </CustomBottomSheet>
</template>

<script>
import CustomBottomSheet from "./../../../../components/general/CustomBottomSheet.vue";
import DataLabelGroup from "./../../../../components/general/DataLabelGroup.vue";
import {
  DEBT_TYPE,
  SCHEDULING_STATUS_TOKENS,
} from "./../../../../utils/constantLists";
import {
  checkPrivilege,
  formateDateTimeLang,
} from "./../../../../utils/functions";
import { hasInstallmentScheduleTime } from "./../../../../utils/privilegeHelper";

export default {
  components: {
    CustomBottomSheet,
    DataLabelGroup,
  },
  props: ["debt"],
  data() {
    return { SCHEDULING_STATUS_TOKENS };
  },
  computed: {
    routeTo() {
      let token = this.debt.debtOfModuleToken;
      let routeTo = {};
      // /:debtOnToken?
      switch (this.debt.debtTypeToken) {
        case DEBT_TYPE.JoiningApplicationSubscription:
          routeTo = {
            name: "JoiningApplicationSubscriptions",
            params: {
              userStudentToken: " ",
              educationalJoiningApplicationToken: " ",
              debtOnToken: token,
            },
          };

          break;
        case DEBT_TYPE.Service:
          routeTo = {
            name: "Services",
            params: {
              debtOnToken: token,
            },
          };
          break;
        case DEBT_TYPE.CountedService:
          routeTo = {
            name: "CountedServices",
            params: {
              debtOnToken: token,
            },
          };
          break;
        case DEBT_TYPE.GroupTransferring:
          routeTo = {
            name: "EducationalGroups",
            params: {
              educationalCategoryToken: token,
            },
          };
          break;
        case DEBT_TYPE.MediaPackageCode:
          routeTo = {
            name: "MediaPackages",
            params: {
              debtOnToken: token,
            },
          };
          break;
        default:
          routeTo = {
            name: "Debts",
          };
          break;
      }
      return routeTo;
    },
  },
  async created() {},
  methods: { checkPrivilege, formateDateTimeLang, hasInstallmentScheduleTime },
};
</script>
