var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row custom-cards"},_vm._l((_vm.debtsData),function(debt,index){return _c('CustomCard',{key:index,attrs:{"className":'col-sm-6 col-md-4 col-lg-3',"title":debt.debtTitleCurrent,"description":'',"imagePath":_vm.defaultImg,"defaultImg":_vm.defaultImg,"index":++index + _vm.filterData.currentIndex}},[_c('li',[_c('button',{attrs:{"title":_vm.$t('info')},on:{"click":function($event){_vm.setDebtData(debt);
          _vm.openBottomSheet('DebtInfo');}}},[_c('img',{attrs:{"src":require("@/assets/images/info.svg")}})])]),(
        _vm.checkPrivilege(_vm.hasInstallmentScheduleTime()) &&
        (_vm.currentTab == 'notScheduled' || _vm.currentTab == 'scheduled')
      )?_c('li',[_c('router-link',{attrs:{"to":{
          name: 'InstallmentScheduleTimes',
          params: {
            userToken: ' ',
            debtToken: debt.debtToken,
          },
        },"target":"_blank","title":_vm.$t('Installments.modelName')}},[_c('img',{attrs:{"src":require("@/assets/images/installments.svg")}})])],1):_vm._e(),(
        _vm.checkPrivilege(_vm.hasDebtCancelInstallment()) &&
        _vm.currentTab == 'scheduled'
      )?_c('li',[_c('button',{attrs:{"title":_vm.$t('Installments.cancel')},on:{"click":function($event){return _vm.setDebtDataToCancel(debt)}}},[_c('img',{attrs:{"src":require("@/assets/images/cancel.svg")}})])]):_vm._e(),_c('li',[_c('button',{attrs:{"title":_vm.$t('actionsData.modelName')},on:{"click":function($event){_vm.setDebtData(debt);
          _vm.openBottomSheet('ActionsData');}}},[_c('img',{attrs:{"src":require("@/assets/images/actions-data.svg")}})])])])}),1)}
var staticRenderFns = []

export { render, staticRenderFns }